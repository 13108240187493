<template>
  <form-modal
    :title="`Change Password`"
    ref="formmodal"
    @save="handleSubmit"
    @cancel="cancel"
    size="sm"
  >
    <validation-observer ref="editProfileForm">
      <div class="auth-register-form mt-2">
        <!-- Email -->
        <b-form-group label="Email" label-for="email">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              :state="errors.length > 0 ? false : null"
              name="register-email"
              placeholder="johndoe"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- username -->
        <b-form-group label="Username" label-for="username">
          <validation-provider
            #default="{ errors }"
            name="Username"
            rules="required"
          >
            <b-form-input
              id="username"
              v-model="form.username"
              :state="errors.length > 0 ? false : null"
              name="register-username"
              placeholder="johndoe"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group label="Password" label-for="password">
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="min:6"
            vid="confirmation"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="register-password"
                placeholder="············"
                ref="password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- confirm password -->
        <b-form-group label="Password confirmation" label-for="passwordConfirm">
          <validation-provider
            #default="{ errors }"
            name="passwordConfirm"
            :rules="`${form.password ? 'required|' : ''}confirmed:confirmation`"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="passwordConfirm"
                v-model="form.password_confirmation"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
    </validation-observer>
  </form-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import { mapState } from "vuex"
export default {
  mixins: [togglePasswordVisibility],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapState("UserViews", ["reload"]),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon"
    },
  },
  data() {
    return {
      form: {
        id: "",
        email: "",
        username: "",
        password: "",
        password_confirmation: "",
      },
    }
  },
  methods: {
    show(data) {
      this.clearForm()
      if (!data) {
        return ""
      }
      this.form.id = data.id
      this.form.username = data.username
      this.form.email = data.email
      this.switch("show")
    },
    async handleSubmit() {
      try {
        const success = await this.$refs.editProfileForm.validate()
        if (!success) {
          return ""
        }
        await this.api.post(`/api/users/updateProfile`, this.form)
        await this.alert.success({ title: "Update Profile Success", text: "" })

        this.reload.creditData = !this.reload.creditData
        this.switch("hide")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    cancel() {
      this.clearForm()
    },
    clearForm() {
      const form = this.form
      Object.keys(form).forEach((fill) => [(form[fill] = "")])
      this.switch("hide")
    },
    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
